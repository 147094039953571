<template>
    <div class="text-center">
        <h3 class="m-t-40 fs23">{{ $t("CANCELLATION.CANCEL_FREE_CHARGE_LOADING") }}</h3>
        <h1 class="m-t-30">{{ $t("CANCELLATION.CANCELLATION_SUCCESSFUL_TXT") }}</h1>
        <h3 class="m-t-30 fs23">הטעינה בוטלה באופן מלא בסכום של 59 ש"ח</h3>
        <button class="primary-btn result-btn">
            {{ $t("CANCELLATION.CLOSED") }}
        </button>
    </div>
</template>

<script>
export default {
    name: "CancellationSuccess",
    mounted() {
        document.getElementsByTagName("html")[0].style.background = "#A5FFB7";
    },
}
</script>

<style lang="scss" scoped>
.result-btn {
    margin: 0 auto;
    margin-top: 70px;
    max-width: 400px;
}
</style>